import React, { useState } from "react";
import {
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    DialogTitle,

} from "@material-ui/core";
import { useHistory, Link } from "react-router-dom";

import MenuIcon from "@material-ui/icons/Menu";
import CancelIcon from '@material-ui/icons/Cancel';
import { useTranslation, Trans } from 'react-i18next';

    
const drawerWidth = 240;

const useStyles = makeStyles(() => ({
    link: {
        textDecoration: "none",
        color: "white",
        fontSize: "20px",
        "&:hover": {
            color: "yellow",
            borderBottom: "1px solid white",
        },
    },
    title: {
        textDecoration: "none",
        color: "white",
        fontSize: '30px',
    },

    icon: {
        color: "blue"
    },
    drawerPaper: {
        width: drawerWidth,
        //height: '400px',
        //height: 'calc(100% - 200px)',
        backgroundColor: '#333333'
        //backgroundColor: "white"
        //top: 64,
    },
    divider: {
        backgroundColor: "#f2f0eb",
        light: true,
    },
    closeButton: {
        color: "white",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerTitle: {
        display: 'flex',
        //justifyContent: 'flex-end',
        justifyContent: 'space-between',
        padding: '0 !important',
    }
}));

function DrawerComponent({signInRedirect, signupRedirect}) {
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    const [openDrawer, setOpenDrawer] = useState(false);
    const history = useHistory();
    const handleLink = async (linkURL, bProcessURL) => {
        setOpenDrawer(false);
        if(bProcessURL)
            history.push("/" + i18n.resolvedLanguage + linkURL);
        else   
            history.push(linkURL);
    };

    return (
        <>
            <Drawer
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                anchor='right'
                className={classes.drawer}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >

                <List>
                    <div className={classes.drawerTitle}>
                        <ListItem onClick={() => setOpenDrawer(false)}>
                            <ListItemText>
                                <Link to={'/' + i18n.resolvedLanguage} className={classes.title}>{t("common.prodName2")}</Link>
                            </ListItemText>
                        </ListItem>
                        <DialogTitle disableTypography >
                            <IconButton onClick={() => setOpenDrawer(false)} className={classes.closeButton} >
                                <CancelIcon size="large"/>
                            </IconButton>
                        </DialogTitle>
                    </div>
                    <Divider className={classes.divider} />
                    <ListItem onClick={() => handleLink("/", true)}>
                        <ListItemText>
                            <Link to={'/' + i18n.resolvedLanguage +"/"} className={classes.link}>{t("menu.pollList")}</Link>
                        </ListItemText>
                    </ListItem>
                    {i18n.resolvedLanguage === "zh" &&<Divider className={classes.divider} /> }
                    {i18n.resolvedLanguage === "zh" && <ListItem onClick={() => handleLink("/blogs", true)}>
                        <ListItemText>
                            <Link to={'/' + i18n.resolvedLanguage +"/blogs"} className={classes.link}>{t("menu.blogs")}</Link>
                        </ListItemText>
                    </ListItem>}
                    <Divider className={classes.divider} />
                    <ListItem onClick={() => handleLink(signInRedirect, false)}>
                        <ListItemText>
                            <Link to={signInRedirect} className={classes.link}>{t("menu.login")}</Link>
                        </ListItemText>
                    </ListItem>
                    <Divider className={classes.divider} />
                    <ListItem onClick={ () => handleLink(signupRedirect, false)}>
                        <ListItemText>
                            <Link to={signupRedirect} className={classes.link}>{t("menu.signup")}</Link>
                        </ListItemText>
                    </ListItem> 
                    <Divider className={classes.divider} /> 
                </List>
            </Drawer>
            <IconButton onClick={() => setOpenDrawer(!openDrawer)} className={classes.icon} >
                <MenuIcon fontSize="large" />
            </IconButton>
        </>
    );
}
export default DrawerComponent;
//how to add icon: https://medium.com/@modularcoder/reactjs-multi-level-sidebar-navigation-menu-with-typescrip-materialui-251943c12dda