const getNewOptObj = (optObj, createNew) => {
    
    const newOptObj = {
        option: optObj.option,
        votes: createNew? 1: (Number(optObj.votes) + 1),
    };
    return newOptObj;
}
const getNewQstObj = (qstObj, formData, createNew, index) => {
    const { answers } = formData;
    const newQstObj = {
        statement: qstObj.statement,
        stmtId: qstObj.stmtId,
        votes: createNew? 1: (Number(qstObj.votes) + 1),
        options: qstObj.options.map((item) =>
            (item._id + '' in answers[index].answerIds) ? getNewOptObj(item, createNew) : item
        )
    };
    return newQstObj;
}

const createNewOptObj = (optObj, votes) => {
    
    const newOptObj = {
        option: optObj.option,
        votes,
    };
    return newOptObj;
}
const createNewQstObj = (qstObj, formData, index) => {
    const { answers } = formData;
    const newQstObj = {
        statement: qstObj.statement,
        stmtId: qstObj.stmtId,
        votes: 1,
        options: qstObj.options.map((item) => 
            //  item.option !== answer ? createNewOptObj(item, 0) : createNewOptObj(item, 1)
            (item._id + '' in answers[index].answerIds) ? createNewOptObj(item, 1): createNewOptObj(item, 0)
        )
        //if (opt._id + '' in answers[index].answerIds)
    };
    return newQstObj;
}

const getNewVoteObj = (voteObject, formData, createNew, bCnty) => {
    
    var index = 0;
    const newVotesObj = {
        poll: voteObject.poll,
        country: voteObject.country,
        region: bCnty? "" : voteObject.region,
        votes: createNew ? 1: (Number(voteObject.votes) + 1),
        questions: voteObject.questions.map((item) =>
            {
                var retObj = getNewQstObj(item, formData, createNew, index);
                index++;
                return retObj;
            }),
        //    item.stmtId !== stmtId ? item : getNewQstObj(item, formData, createNew)),
    };

    return newVotesObj;
}

const createNewVoteObj = (poll, formData, geo)  => {
    var index = 0;
    const newVotesObj = {
        poll: poll._id,
        country: geo.cnty,
        region: geo.reg,
        votes: 1,
        questions: poll.questions.map((item) => 
            {
                var retObj = createNewQstObj(item, formData, index);
                index++;
                return retObj;
            }),
            // item.stmtId !== stmtId ? createNewQstObj(item, formData, 0) : 
            //     createNewQstObj(item, formData, 1)),
    };

    return newVotesObj;
}

export const getNewVotesObj = (pendingDataLoc, votesByLocal, bCnty=true)  => {
    var hasRec = false;
    const {formData, poll, geo} = pendingDataLoc;
    for(let x in votesByLocal) {

        if(bCnty) {
            if(votesByLocal[x].country === geo.cnty) {
                hasRec = true; 
                break;
            }
        }
        else {
            if(votesByLocal[x].country === geo.cnty && 
                    votesByLocal[x].region === geo.reg) {
                hasRec = true; 
                break;
            }  
        }
    }
    var newVotesObj = null;
    console.log("hasRec value", hasRec);
    if(hasRec) {
        newVotesObj =  bCnty? 
            votesByLocal.map(item =>
                item.country !== geo.cnty ? item : getNewVoteObj(item, formData, false, bCnty))
            :
            votesByLocal.map(item =>
            item.region !== geo.reg ? item : getNewVoteObj(item, formData, false, bCnty));
    }
    else {
        newVotesObj = votesByLocal.map(item => item);
        const newSVoteObj = createNewVoteObj(poll, formData, geo);
        console.log(JSON.stringify(newSVoteObj, null, 2));
        //todo2-pz: need to be more efficient
        newVotesObj.push(newSVoteObj);
    }
    console.log("new country/reg votes: ", JSON.stringify(newVotesObj));
    return newVotesObj;
}
