import { AUTH, LOGOUT, AUTH_UPDATE_TOKEN, AUTH_UPDATE_TOKEN_FAIL, AUTH_ANON } from '../constants/actionTypes';

const auth = (state = { authData: null, rfCount: 0, refreshStatus: 1 }, action) => {
    switch (action.type) {
        case AUTH:
            //console.log("index auth reducer ", action?.data);
            localStorage.setItem('profile', JSON.stringify(action?.data));
            return { ...state, authData: action?.data, refreshStatus: 1 };
        case AUTH_UPDATE_TOKEN:
            var profile = JSON.parse(localStorage.getItem('profile'));
            profile.token = action?.data.token;
            profile.rToken = action?.data.rToken;
            //console.log("index auth reducer ", action?.data);
            localStorage.setItem('profile', JSON.stringify(profile));
            return { ...state, authData: profile, refreshStatus: 1 };
        case AUTH_UPDATE_TOKEN_FAIL:
            //console.log("index auth reducer ", action?.data);

            let count = state.rfCount + 1;

            if (action?.data.status === 401 || count > 3) {
                //clean up the local profile sinc refresh is failed. 
                console.log("failed to refresh token 3 times or failed with 401, cleanup auth token");
                localStorage.removeItem('profile');
                return { ...state, authData: null, rfCount: 0, refreshStatus: 0};
            }

            return { ...state, rfCount: count };
        case AUTH_ANON:
            //console.log("index auth reducer ", action?.data);
            localStorage.setItem('anon-t', JSON.stringify(action?.data));
            return state;
        case LOGOUT:
            console.log("handle the logout");
            localStorage.removeItem('profile');
            return { ...state, authData: null, rfCount: 0, refreshStatus: 1 };
        default:
            return state;
    }

};

export default auth;