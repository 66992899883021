export const CREATE= 'CREATE';
export const FETCH_ALL = 'FETCH_ALL';
export const FETCH_POLL = 'FETCH_POLL';
export const FETCH_BY_SEARCH = 'FETCH_BY_SEARCH';
export const DELETE= 'DELETE';
export const LIKE = 'LIKE';
export const UPDATE= 'UPDATE';
export const AUTH = 'AUTH';
export const AUTH_UPDATE_TOKEN_FAIL = 'AUTH_UPDATE_TOKEN_FAIL';
export const AUTH_UPDATE_TOKEN = 'AUTH_UPDATE_TOKEN';
export const AUTH_ANON = 'AUTH_ANON';

export const LOGOUT = 'LOGOUT';
export const START_LOADING = 'START_LOADING';
export const END_LOADING = 'END_LOADING';
export const COMMENT_POLL = 'COMMENT_POLL';
export const ADD_ERROR = 'ADD_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';

export const FETCH_POST = 'FETCH_POST';
export const COMMENT_POST = 'COMMENT_POST';
export const VOTE = 'VOTE';
export const UPDATE_POLL_OPT = 'UPDATE_POLL_OPT';
//fetch user poll votes section

export const FETCH_USER_VOTES = 'FETCH_USER_VOTES';
export const START_LOADING_USER_VOTES = 'START_LOADING_USER_VOTES';
export const END_LOADING_USER_VOTES = 'END_LOADING_USER_VOTES';
export const UPDATE_USER_VOTES = 'UPDATE_USER_VOTES';


//by country
export const FETCH_VOTES_BY_CNTYS = 'FETCH_VOTES_BY_CNTYS';
export const START_LOADING_VOTES_BY_CNTYS = 'START_LOADING_VOTES_BY_CNTYS';
export const END_LOADING_VOTES_BY_CNTYS = 'END_LOADING_VOTES_BY_CNTYS';
export const UPDATE_VOTES_BY_CNTYS = 'UPDATE_VOTES_BY_CNTYS';


//By region
export const FETCH_VOTES_BY_REGS = 'FETCH_VOTES_BY_REGS';
export const START_LOADING_VOTES_BY_REGS = 'START_LOADING_VOTES_BY_REGS';
export const END_LOADING_VOTES_BY_REGS = 'END_LOADING_VOTES_BY_REGS';
export const UPDATE_VOTES_BY_REGS = 'UPDATE_VOTES_BY_REGS';


//By region
export const FETCH_POLL_REL = 'FETCH_POLL_REL';
export const START_LOADING_POLL_REL = 'START_LOADING_POLL_REL';
export const END_LOADING_POLL_REL = 'END_LOADING_POLL_REL';
export const UPDATE_POLL_REL = 'UPDATE_POLL_REL';

export const FETCH_VOTES_WEEKLY = 'FETCH_VOTES_WEEKLY';
export const START_LOADING_VOTES_WEEKLY = 'START_LOADING_VOTES_WEEKLY';
export const END_LOADING_VOTES_WEEKLY = 'END_LOADING_VOTES_WEEKLY';
export const UPDATE_VOTES_WEEKLY = 'UPDATE_VOTES_WEEKLY';


export const FETCH_TAGS = 'FETCH_TAGS';