import {
    FETCH_VOTES_BY_REGS, START_LOADING_VOTES_BY_REGS,
    END_LOADING_VOTES_BY_REGS, UPDATE_VOTES_BY_REGS
} from '../constants/actionTypes';

import {getNewVotesObj} from './votesByUtils';

const initialState = { 
    isLoadingVotesByRegs: false, 
    pollId: 0, 
    votesByRegs: [], 
    pendingData: null,
    bEmptyResults: true,
};


const votesByRegs =  (state = initialState, action) => {
    
    switch (action.type) {
        case START_LOADING_VOTES_BY_REGS:
            console.log("start loading votes by regions at reducer");

            return { ...state, votesByRegs: [], isLoadingVotesByRegs: true, pollId: 0 };
        case END_LOADING_VOTES_BY_REGS:
            console.log("end loading votes by regions at reducer");
            return { ...state, isLoadingVotesByRegs: false };
        case UPDATE_VOTES_BY_REGS:
            console.log("UPDATE_VOTES_BY_REGS: entering the reducer to update votes by countries");

            
            //need to parse the form data and update the vote counts
            //now we know the country
            
            const {poll} =action.payload;

            if(state.pollId !== poll._id) {
                console.log("UPDATE_VOTES_BY_REGS: the poll id is different, add to pending data ");
                return {...state, pendingData: action.payload};
            }

            const newVotesObj = getNewVotesObj(action.payload, state.votesByRegs, false);

            return {...state, pollId: poll._id, bEmptyResults: false,
                votesByRegs: newVotesObj.sort((a, b) => parseInt(b.votes) - parseInt(a.votes))};

        case FETCH_VOTES_BY_REGS:
            console.log("FETCH_VOTES_BY_REGS: entering the reducer to set votes");
            const { data, pollId } = action.payload;
            // if(state.votesByRegs.length === 1) { 
            //     console.log("first vote, keep old record created by browser");
            //     return state;
            // }
            var votesObj = data;
            //todo3-pz, may need to delay updatePollsStats to call later. 
            if(state.pendingData) {
                //there is pending data. 
                console.log("FETCH_VOTES_BY_REGS: processing pending update")
                votesObj = getNewVotesObj(state.pendingData, data, false);
            }

            return {
                ...state, pollId, pendingData: null, bEmptyResults: (votesObj.length===0),
                votesByRegs: votesObj.sort((a, b) => parseInt(b.votes) - parseInt(a.votes))
            };
        default:
            return state;
    }
};

export default votesByRegs;