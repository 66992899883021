import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  appBar: {
    borderRadius: 15,
    margin: '30px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mainContainer2: {
    minHeight: '80vh',
   },
  heading: {
    color: 'rgba(0,183,255, 1)',
  },
  image: {
    marginLeft: '15px',
  },
  [theme.breakpoints.down('sm')]: {
    mainContainer: {
      flexDirection: "column-reverse",
    },
  },
  cookieAcceptContainer: {
    marginLeft: '30px',
    marginRight: '30px',
  },
  footer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    
   },
   footerColumn: {
     height:"50px"
   }
}));