import React from 'react'
import {Typography } from '@material-ui/core';

const DebugPage = () => {

  var profile = JSON.parse(localStorage.getItem('profile'));
  const token = profile?.token;
  const rtoken = profile?.rToken;
  const result = profile?.result;

  var anonT = JSON.parse(localStorage.getItem('anon-t'));
  const result2 = anonT?.result;

  return (
    <div >
      <Typography variant="body1"  >token: {token}</Typography>
      <Typography variant="body1"  >rtoken: {rtoken}</Typography>
      <Typography variant="body1"  >result: {JSON.stringify(result)}</Typography> 
      <Typography variant="body1"  >anon result: {JSON.stringify(result2)}</Typography> 
    </div>
  )
}

export default DebugPage