import * as api from '../api';
import { AUTH, AUTH_UPDATE_TOKEN_FAIL, AUTH_UPDATE_TOKEN, AUTH_ANON } from '../constants/actionTypes';


export const signin = (formData, history, referrer, handleFailure) => async (dispatch) => {
    try {
        const { data } = await api.signin(formData);
        dispatch({ type: AUTH, data });
        history.push(referrer ? referrer : '/');
    } catch (err) {
        console.log(err);
        if (err?.response?.data) {
            const { error } = err?.response?.data;
            handleFailure(error);
            return;
        }
        handleFailure(null);
    }
}

export const signinSocial = (formData, history, referrer, handleFailure) => async (dispatch) => {
    try {
        const { data } = await api.signinSocial(formData);
        dispatch({
            type: AUTH,
            data
        });
        history.push(referrer ? referrer : '/');
    } catch (err) {
        console.log(err);
        if (err?.response?.data) {
            const { error } = err?.response?.data;
            handleFailure(error);
            return;
        }
        handleFailure(null);
    }
}


export const socialSignup = (formData, history, referrer, handleFailure) => async (dispatch) => {
    try {
        const { data } = await api.socialSignup(formData);
        dispatch({ type: AUTH, data });
        history.push(referrer ? referrer : '/');
    } catch (err) {
        console.log(err);
        if (err?.response?.data) {
            const { error } = err?.response?.data;
            handleFailure(error);
            return;
        }
        handleFailure(null);
    }
}

export const createIncognitoUser = () => async (dispatch) => {
    try {
        const { data } = await api.createIncognitoUser();
        dispatch({ type: AUTH_ANON, data });
    } catch (err) {
        console.log(err);
        if (err?.response?.data) {
            //const { error } = err?.response?.data;
            return;
        }
    }
}



export const refresh = (formData) => async (dispatch) => {
    try {
        const { data } = await api.refresh(formData);
        dispatch({ type: AUTH_UPDATE_TOKEN, data });
    } catch (err) {
        console.log("refresh error: ", err);

        console.log("refresh failed with status code ", err.response.status);

        if (err?.response?.data) {
            const { error } = err?.response?.data;
            console.log("refresh server specific error: ", error)
            //handleFailure(error);
            //return;
        }
        dispatch({ type: AUTH_UPDATE_TOKEN_FAIL, data: { status: err.response.status } });
        //handleFailure(null);
    }
}

export const signup = (formData, history, referrer, handleFailure) => async (dispatch) => {
    try {
        const { data } = await api.signup(formData);
        dispatch({ type: AUTH, data });
        history.push(referrer ? referrer : '/');
    } catch (err) {
        console.log(err);
        if (err?.response?.data) {
            const { error } = err?.response?.data;
            handleFailure(error);
            return;
        }
        handleFailure(null);
    }
}

export const activate = (activationToken, handleSuccess, handleFailure) => async (dispatch) => {
    try {
        await api.activateUser({ activationToken });
        handleSuccess();
    } catch (err) {
        console.log(err);
        if (err?.response?.data) {
            const { error } = err?.response?.data;
            if (error) {
                handleFailure(error);
                return;
            }
        }
        handleFailure(null);
    }
}


export const forgotPassword = (formData, handleSuccess, handleFailure) => async (dispatch) => {
    try {
        await api.forgotPassword(formData);
        handleSuccess();
    } catch (err) {
        console.log(err);
        if (err?.response?.data) {
            const { error } = err?.response?.data;
            if (error) {
                handleFailure(error);
                return;
            }
        }
        handleFailure(null);
    }
}
export const resetPassword = (param, handleSuccess, handleFailure) => async (dispatch) => {
    try {
        await api.resetPassword(param);
        handleSuccess();
    } catch (err) {
        console.log(err);
        if (err?.response?.data) {
            const { error } = err?.response?.data;
            if (error) {
                handleFailure(error);
                return;
            }
        }
        handleFailure(null);
    }
}
