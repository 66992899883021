import {
    FETCH_ALL, FETCH_POLL, UPDATE,
    DELETE, LIKE, CREATE, FETCH_BY_SEARCH,
    START_LOADING, END_LOADING, COMMENT_POLL,
    VOTE
} from '../constants/actionTypes';

const clonePoll = (tempPoll) => {
    return {
        _id: tempPoll._id,
        user: tempPoll.user,
        created: tempPoll.created,
        name: tempPoll.name,
        questions: tempPoll.questions,
        status: tempPoll.status,
        tags: tempPoll.tags,
        votes: tempPoll.votes,
        likes: tempPoll.likes,
        allowCustom: tempPoll.allowCustom,
        closeDate: tempPoll.closeDate,
        desc: tempPoll.desc,
        allowAnon: tempPoll.allowAnon,
        media: tempPoll.media,
        longDesc: tempPoll.longDesc,
        descHtml: tempPoll.descHtml,
    };
}

const collapseChildQsts = (tempPoll) => {
    //need to regroup the poll with parent and children. 
    //loop through the polls. 
    const mapData = new Map();
    var newQsts = [];
    var newQstsFlat = [];
    var shift = 0;
    var parentQstId = -1;
    tempPoll.questions.forEach((qst, index) => {

        if (!qst.parentQstId || qst.parentQstId === -1) {

            newQsts.push(qst);
            qst.displayIndex = newQsts.length;
            qst.shift = shift;
            mapData.set(qst.stmtId, qst);

        }
        else {
            //a child question statement, find its parent. 
            const parentQst = mapData.get(qst.parentQstId);
            if (qst.parentQstId !== parentQstId) {
                parentQstId = qst.parentQstId;
                shift++;
            }
            if (parentQst) {
                if (!parentQst.childQsts) {
                    parentQst.childQsts = [];
                }
                parentQst.childQsts.push(qst);
                qst.displayIndex = parentQst.displayIndex;
                qst.displaySubIndex = parentQst.childQsts.length;
                qst.parentStmt = parentQst.statement;
                qst.shift = shift;
            }
        }
    });
    tempPoll.questions.forEach((qst) => {
        if (!qst.childQsts) {
            //won't include questions with children
            newQstsFlat.push(qst);
        }
    });

    return { newQsts, newQstsFlat };
}

const transPoll = (poll, lang) => {


    if (poll.lang === lang) return poll;
    if (!poll.trans) return poll;
    poll.name = poll.trans.name;
    poll.desc = poll.trans.desc;
    poll.descHtml = poll.trans.descHtml;
    poll.questions.forEach(qst => {
        if (qst.trans) {
            qst.statement = qst.trans.name;
            qst.options.forEach((opt, index) => {
                const newIndex = index + 1;
                const newVal = qst.trans["opt" + newIndex];
                if(newVal) opt.option = newVal;
            })
        }
    });
    return poll;


}


const transPolls = (polls, lang) => {

    return polls.map(poll => {
        if (poll.lang === lang) return poll;
        if (!poll.trans) return poll;
        poll.name = poll.trans.name;
        poll.desc = poll.trans.desc;
        return poll;
    });

}

const polls = (state = { isLoading: true, polls: [], geo: { cnty: "", reg: "" } }, action) => {

    switch (action.type) {
        case START_LOADING:
            console.log("poll start loading");
            return { ...state, isLoading: true };
        case END_LOADING:
            console.log("poll end loading");
            return { ...state, isLoading: false };
        case FETCH_POLL:
            console.log("feteh poll at reducer");
            var origPoll = transPoll(action.payload.poll, action.lang);
            var newPoll = clonePoll(origPoll);
            var procQsts = collapseChildQsts(origPoll);
            newPoll.questions = procQsts.newQsts;
            origPoll.questions = procQsts.newQstsFlat;

            return { ...state, poll: newPoll, origPoll: origPoll, geo: action.payload.geo };
        case FETCH_ALL:
            console.log("get all at reducers");
            return {
                ...state,
                polls: transPolls(action.payload.data, action.lang),
                currentPage: action.payload.currentPage,
                numberOfPages: action.payload.numberOfPages,
                poll: null,
                origPoll: null,
            };
        case FETCH_BY_SEARCH:
            console.log("fetch by search at reducer %d, current page: %d, total page: %d",
                action.payload.data.length, action.payload.currentPage, action.payload.numberOfPages);
            return {
                ...state,
                polls: transPolls(action.payload.data, action.lang),
                currentPage: action.payload.currentPage,
                numberOfPages: action.payload.numberOfPages,
                poll: null,
                origPoll: null,
            };

        case COMMENT_POLL:

            const newpoll1 = state.polls.map((poll) => {

                return poll._id === action.payload._id ? action.payload : poll;
            });
            return { ...state, polls: newpoll1 }


        case CREATE:
            return { ...state, polls: [...state.polls, action.payload] };
        case UPDATE:
        case LIKE:

            const newpoll = state.polls.map((poll) => {
                if (poll._id === action.payload._id) {
                    console.log("same id");
                }
                else {
                    console.log("different id");
                }

                return poll._id === action.payload._id ? action.payload : poll;
            });
            return { ...state, polls: newpoll };

        case VOTE:
            origPoll = transPoll(action.payload, action.lang);
            newPoll = clonePoll(origPoll);
            procQsts = collapseChildQsts(origPoll);
            newPoll.questions = procQsts.newQsts;
            origPoll.questions = procQsts.newQstsFlat;

            const newPolls = state.polls.map((poll) => {
                return poll._id === action.payload._id ? action.payload : poll;
            });

            return { ...state, polls: newPolls, poll: newPoll, origPoll: origPoll };


        case DELETE:
            return { ...state, polls: state.poll.filter((poll) => poll._id !== action.payload) };
        default:
            return state;
    }
};

export default polls;