import {
    FETCH_USER_VOTES, START_LOADING_USER_VOTES,
    END_LOADING_USER_VOTES, UPDATE_USER_VOTES
} from '../constants/actionTypes';
import cuid from 'cuid';

const userVotes = (state = { isLoadingUserVotes: false, userVotes: {pollId: '', data: []} }, action) => {

    switch (action.type) {
        case START_LOADING_USER_VOTES:
            return { ...state, userVotes: {pollId: '', data: []}, isLoadingUserVotes: true };
        case END_LOADING_USER_VOTES:
            return { ...state, isLoadingUserVotes: false };
        case FETCH_USER_VOTES:
            console.log("feteh user poll votes at reducer");
            return { ...state, userVotes: action.payload };
        case UPDATE_USER_VOTES:
            console.log("update user poll votes at reducer");
            //construct a new user votes object
            const { poll} = action.payload;
            var profile = JSON.parse(localStorage.getItem('profile'));
            if(!profile) {
                console.log("fall back to anon profile");
                profile = JSON.parse(localStorage.getItem('anon-t'));
            }
            if(!profile) {
                console.log("could not find profile");
                return state;
            }
            const nowTime = new Date();
            const obj = {_id: cuid(), user: profile?.result?._id, 
                poll: poll._id, pollName: poll.name, 
                votedDate: nowTime.toISOString() };
            //todo2-pz: might need to remove below logging if no issues in future
            console.log("user votes object: " + JSON.stringify(state.userVotes));
            const resultObj  = {pollId: poll._id, data:[...state.userVotes.data, obj ]}
            //console.log("user votes: ", JSON.stringify(resultObj));
            return { ...state, userVotes: resultObj };
        default:
            return state;
    }
};

export default userVotes;