import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTopDefault() {
  const { pathname } = useLocation();

  useEffect(() => {
    console.log("useEffect ScrollToTopDefault");
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  }, [pathname]);

  return null;
}
