import axios from 'axios';

const baseURL = (process.env.REACT_APP_NODE_ENV === "DEV") ? process.env.REACT_APP_API_SERVER_URL_DEV : process.env.REACT_APP_API_SERVER_URL_PROD;
const API = axios.create({baseURL: baseURL});

API.interceptors.request.use( (req) => {
    // var parser = new UAParser();
    // console.log(parser.getResult());
    // req.headers['X-Custom-Os'] = parser.getOS();
    // req.headers['X-Custom-Brower-Name'] = parser.getBrowser().name;
    if(localStorage.getItem('profile')) {
        req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
    }
    else if(localStorage.getItem('anon-t')) {
        //anon user
        req.headers.Authorization = `Bearer anon-${JSON.parse(localStorage.getItem('anon-t')).result._id}`;
    }
    return req;
});

export const fetchPosts = (page) => API.get(`/posts?page=${page}`);
export const fetchPostsBySearch = (searchQuery) => API.get(`/posts/search?searchQuery=${searchQuery.search || 'none'}&tags=${searchQuery.tags}&page=${searchQuery.page}`);
export const fetchPost = (id) => API.get(`/posts/${id}`);

export const createPost = (newPost) => API.post('/posts', newPost);

export const updatePost = (id, post) => API.patch(`/posts/${id}`, post);

export const deletePost = (id) => API.delete(`/posts/${id}`);

export const likePost = (id) => API.patch(`/posts/${id}/likePost`);
export const commentPost = (comment, id) => API.post(`/posts/${id}/commentPost`, {comment});

export const signin = (formData) => API.post('/users/signin', formData, {withCredentials: true});
export const signup = (formData) => API.post('/users/signup', formData, {withCredentials: true});
export const socialSignup = (formData) => API.post('/users/socialSignup', formData, {withCredentials: true});
export const signinSocial = (formData) => API.post('/users/signinSocial', formData, {withCredentials: true});
export const activateUser = (activationToken) => API.post('/users/activate', activationToken);
export const forgotPassword = (formData) => API.post('/users/forgotPassword', formData);
export const resetPassword = (param) => API.post('/users/resetPassword', param);
export const refresh = (rToken) => API.post('/users/refresh', rToken, {withCredentials: true});
export const createIncognitoUser = () => API.post('/users/createIncognitoUser', null);
// for polls
export const fetchPolls = (page, lang, docReferer) => API.get(`/polls?page=${page}&lang=${lang}&from=${docReferer}`);
export const fetchPollsBySearch = (searchQuery, lang, docReferer) => API.get(`/polls/search?lang=${lang}&searchQuery=${searchQuery.search || 'none'}&tags=${searchQuery.tags}&from=${docReferer}`);
export const fetchPoll = (id, docReferer) => API.get(`/polls/${id}?from=${docReferer}`);

export const createPoll = (newPoll) => API.post('/polls', newPoll);

export const updatePoll = (id, poll) => API.patch(`/polls/${id}`, poll);

export const deletePoll = (id) => API.delete(`/polls/${id}`);

export const likePoll = (id) => API.patch(`/polls/${id}/likePoll`);
export const commentPoll = (comment, id) => API.post(`/polls/${id}/commentPoll`, {comment});

export const votePoll = (id, formData, docReferer) => API.patch(`/polls/${id}/votePoll?from=${docReferer}`, formData);

export const fetchUserVotes = (id) => API.get(`/polls/${id}/getUserVotes?limit=10`);

export const updatePollStats = (id, formData) => API.patch(`/polls/${id}/updatePollStats`, formData);

export const fetchVotesByCountries = (id) => API.get(`/polls/${id}/getVotesByCountries`);

export const fetchVotesByRegions = (id) => API.get(`/polls/${id}/getVotesByRegions`);

export const fetchPollRel = (id) => API.get(`/polls/${id}/getPollRel`);

export const sendContactData = (contactData) => API.post('/contacts', contactData);

export const fetchTags = () => API.get(`/tags`);

export const addPollOpt = (id, customOpts) => API.patch(`/polls/${id}/addOpt`, customOpts);

export const fetchVotesWeekly = (id) => API.get(`/polls/${id}/getVotesWeekly`);