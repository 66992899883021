import {
    FETCH_VOTES_WEEKLY, START_LOADING_VOTES_WEEKLY,
    END_LOADING_VOTES_WEEKLY, UPDATE_VOTES_WEEKLY
} from '../constants/actionTypes';


const initialState = { 
    isLoadingVotesWeekly: false, 
    pollId: 0, 
    votesWeekly: [], 
};


const votesWeekly =  (state = initialState, action) => {
    
    switch (action.type) {
        case START_LOADING_VOTES_WEEKLY:
            console.log("start loading votes weekly at reducer");

            return { ...state, votesWeekly: [], isLoadingVotesWeekly: true, pollId: 0 };
        case END_LOADING_VOTES_WEEKLY:
            console.log("end loading votes weekly at reducer");
            return { ...state, isLoadingVotesWeekly: false };
        case UPDATE_VOTES_WEEKLY:
            console.log("UPDATE_VOTES_WEEKLY: not impelemented since not used right now");
            return {...state};

        case FETCH_VOTES_WEEKLY:
            console.log("FETCH_VOTES_WEEKLY: entering the reducer to set votes");
            const { data, pollId } = action.payload;
            return {
                ...state, pollId, 
                votesWeekly: data
            };
        default:
            return state;
    }
};

export default votesWeekly;