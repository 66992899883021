import { makeStyles } from '@material-ui/core/styles';
import { deepPurple } from '@material-ui/core/colors';

export default makeStyles((theme) => ({
  appBar: {
    //borderRadius: 5,
    margin: '5px 0px 30px 0px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 5px',
    width: "100%",
    //backgroundColor: "#D3D3D3",
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
    },
  },
  heading: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontSize: '2em',
    fontWeight: 300,
  },
  image: {
    marginLeft: '10px',
    marginTop: '5px',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  persona: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  personaGroupText: {
    display: 'flex',
    flexDirection: 'column',
  },
  personaPrimaryText: {
    //marginTop: '10px',
    marginLeft: '5px',
    fontWeight: 600
  },
  personaText: {
    //marginTop: '10px',
    marginLeft: '5px',
  },
  personaDivider: {
    marginTop: '1px',
    marginBottom: '1px'
  },
  personaMenu: {
    marginTop: '5px',
    marginLeft: '3px',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    '&:hover': {
      cursor: "pointer",
    },
  },
  personaMenuText: {
    marginLeft: '5px',
    fontWeight: 500
  },
  profile: {
    display: 'flex',
    justifyContent: 'space-between',
    //width: '400px',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      justifyContent: 'center',
    },
  },
  logout: {
    marginLeft: '20px',
  },
  textButton: {
    '&:hover': {
      backgroundColor: '#f7f7f7',
      borderColor: '#0062cc',
      boxShadow: 'none',
      color: '#332a63',
    },
  //   "&:hover": {
  //     borderBottom: "1px solid yellow",
  // },
    marginLeft: '10px',
    marginRight: '10px',
  },

  login: {
    // '&:hover': {
    //   backgroundColor: '##f7f7f7',
    //   borderColor: '#0062cc',
    //   boxShadow: 'none',
    //   color: '#332a63',
    // },\
    '&:hover': {
      backgroundColor: '#2a632e',
      borderColor: '#2a632e',
      boxShadow: 'none',
      color: 'white',
    },
    marginLeft: '10px',
    marginRight: '10px',
  },

  register: {

    '&:hover': {
      backgroundColor: '#3f51b5',
      borderColor: '#0062cc',
      boxShadow: 'none',
      color: 'white',
    },
    marginLeft: '10px',
    marginRight: '10px',
  },

  menuButton: {
    marginLeft: '0px',
    marginRight: '0px',
    color: "black",
  },

  userName: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  },
  brandContainer: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
  },
  purple: {
    marginTop: "10px",
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
    '&:hover': {
      cursor: "pointer",
    },
  },
  searchBar: {
    width: "50%",
    
  },
}));