import { FETCH_TAGS} from '../constants/actionTypes';

 const tags = (state = { tags: []}, action) => {
    
    switch (action.type) {
        case FETCH_TAGS:
            const tags = action.payload.tags.map( (tag) =>{ const retObj = {value: tag.tag, label: tag.tag}; return retObj; })
            return {
                ...state,
                tags
            };
        default:
            return state;
    }
};

export default tags;