import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers'
import { composeWithDevTools } from 'redux-devtools-extension';
import './index.css';
import './i18n';


const store = createStore(reducers, composeWithDevTools(applyMiddleware(thunk)));


ReactDOM.render(
    <Provider store={store}><App /></Provider>,
    document.getElementById('root'));