import React, { Suspense, lazy } from 'react';
import { Container } from "@material-ui/core";
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom';
import useStyles from './styles'
import Navbar from './components/Navbar/Navbar';
import ScrollToTopDefault from './ScrollToTopDefault';


// import Home from './components/Home/Home';
// import PollDetails from './components/PollDetails/PollDetails';
// import Auth from './components/Auth/Auth';
// import Signup from './components/Auth/Signup';
// import PrivatePolicy from './components/Pages/PrivatePolicy';
// import Terms from './components/Pages/Terms';
// import AboutPage from './components/Pages/about';
// import CookieConsent from "react-cookie-consent";
// import Footer2 from './components/Footer/Footer2';
// import ContactForm from './components/Form/ContactForm';
// import MyFeedback2 from './components/Form/MyFeedback2';
// import ActivateEmail from './components/Auth/ActivateEmail'
// import ForgotPassword from './components/Auth/ForgotPassword'
// import ResetPassword from './components/Auth/ResetPassword'
// import ScrollToTop from './ScrollToTop';


import { useTranslation, Trans } from 'react-i18next';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_CLIENT_ID, FB_CLIENT_ID } from './constants/constants';
import DelayedFallback from './DelayedFallback';
import DebugPage from './DebugPage';
const Home = lazy(() => import('./components/Home/Home'));
const PollDetailsTab = lazy(() => import('./components/PollDetails/PollDetailsTab'));

const Auth = lazy(() => import('./components/Auth/Auth'));
const Signup = lazy(() => import('./components/Auth/Signup'));
const PrivatePolicy = lazy(() => import('./components/Pages/PrivatePolicy'));
const Terms = lazy(() => import('./components/Pages/Terms'));
const AboutPage = lazy(() => import('./components/Pages/about'));
const CookieConsent = lazy(() => import("react-cookie-consent"));
const Footer2 = lazy(() => import('./components/Footer/Footer2'));
const ContactForm = lazy(() => import('./components/Form/ContactForm'));
const MyFeedback2 = lazy(() => import('./components/Form/MyFeedback2'));
const ActivateEmail = lazy(() => import('./components/Auth/ActivateEmail'));
const ForgotPassword = lazy(() => import('./components/Auth/ForgotPassword'));
const ResetPassword = lazy(() => import('./components/Auth/ResetPassword'));
const ScrollToTop = lazy(() => import('./ScrollToTop'));

const MyFaq = lazy(() => import('./components/Pages/MyFaq'));
const Blogs = lazy(() => import('./components/Pages/Blogs'));


const App = () => {
    const { t, i18n } = useTranslation();


    const classes = useStyles();
    const baseRouteUrl = "/:locale(en|es|zh)?";

    // useEffect(() => {

    //     // 👇️ set style on body element
    //     document.body.style.backgroundColor = 'salmon';
    //     document.body.style.width = '100%';
    //     document.body.style.overflowX = 'hidden';

    //     return () => {
    //       // 👇️ optionally remove styles when component unmounts
    //       document.body.style.backgroundColor = null;
    //     };
    //   }, []);


    return (
        <BrowserRouter>
            <ScrollToTopDefault />

            <Container disableGutters className={classes.mainContainer2} >

                <Navbar />
                <Suspense fallback={<DelayedFallback />}>
                    <Switch>
                        <Route path={baseRouteUrl + "/"} exact component={Home} />
                        <Route path={baseRouteUrl + "/debug"} exact component={DebugPage} />
                        <Route path={baseRouteUrl + "/polls"} exact component={Home} />
                        <Route path={baseRouteUrl + "/polls/search"} exact component={Home} />
                        <Route path={baseRouteUrl + "/polls/:id"} exact component={PollDetailsTab} />
                        <Route path={baseRouteUrl + "/privacy-policy"} exact component={PrivatePolicy} />
                        <Route path={baseRouteUrl + "/terms"} exact component={Terms} />
                        <Route path={baseRouteUrl + "/faq"} exact component={MyFaq} />
                        <Route path={baseRouteUrl + "/blogs"} exact component={Blogs} />
                        <Route path={baseRouteUrl + "/about"} exact component={AboutPage} />
                        <Route path={baseRouteUrl + "/contact"} exact component={ContactForm} />
                        <Route path={baseRouteUrl + "/user/activate/:activation_token"} component={ActivateEmail} exact />
                        <Route path={baseRouteUrl + "/user/forgot-password"} component={ForgotPassword} exact />
                        <Route path={baseRouteUrl + "/user/reset/:reset_token"} component={ResetPassword} exact />

                        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                            <Route path={baseRouteUrl + "/auth"} exact component={Auth} />
                            <Route path={baseRouteUrl + "/signup"} exact component={Signup} />
                        </GoogleOAuthProvider>
                    </Switch>
                </Suspense>
            </Container>


            <Suspense fallback={<DelayedFallback />}>
                <Footer2 />
                <ScrollToTop />
                <MyFeedback2 />
                <div className={classes.cookieAcceptContainer}>
                    <CookieConsent
                        location="bottom"
                        buttonText={t('acceptCookieTitle')}
                        overlay={false}
                        cookieName="_cks_pol_acpt"
                        style={{ background: "#d1cfcb", color: "black", fontSize: '15px' }}
                        buttonStyle={{ backgroundColor: "#4340de", color: "white", fontSize: "16px" }}
                        expires={150}
                    >
                        {t("acceptCookieText.part1")} <Link target="_blank" rel="noopener noreferrer" to={'/' + i18n.resolvedLanguage + "/privacy-policy"} >{t("common.privatePolicy")}</Link> {t("acceptCookieText.part3")} <Link target="_blank" rel="noopener noreferrer" to={'/' + i18n.resolvedLanguage + "/terms"} >{t("common.terms")}</Link>
                    </CookieConsent>
                </div>
            </Suspense>



        </BrowserRouter >
    )

    // return (
    //    <>
    //    <p>testing</p>
    //    <Placeholder.Graph active />
    //    {instance}
    //        <p>testing</p>
    //    </>
    // )
}

export default App;