import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import en_translate from './locales/en/translation.json';
import zh_translate from './locales/zh/translation.json';
import es_translate from './locales/es/translation.json';

// i18n.on('languageChanged', function (lng) {
//     // if the language we switched to is the default language we need to remove the /en from URL
//     if (lng === i18n.options.fallbackLng[0]) {
//       if (window.location.pathname.includes('/' + i18n.options.fallbackLng[0])) {
//         const newUrl = window.location.pathname.replace('/' + i18n.options.fallbackLng[0], '')
//         window.location.replace(newUrl)
//       }
//     }
//   })

i18n
    // i18next-http-backend
    // loads translations from your server
    // https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        //lng: "en",
        ns: "translation",
        debug: true,
        whitelist: ['en', 'zh', 'es'],
        fallbackLng: 'en',
        detection: {
            order: ['path'],
            lookupFromPathIndex: 0,
            checkWhitelist: true
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        // backend: {
        //     // for all available options read the backend's repository readme file
        //     loadPath: '/locales/{{lng}}/{{ns}}.json'
        //   },
        resources: {
            en: {
                translation: en_translate
            },

            zh: {
                translation: zh_translate
            },
            es: {
                translation: es_translate
            },

        }
        // resources: {
        //     en: {
        //         translation: {
        //             description: {
        //                 part1: 'Edit <1>src/App.js</1> and save to reloadPZ.',
        //                 part2: 'Learn React'
        //             },
        //             key: "{{what}} is {{how}}",
        //             counter_one: 'Changed language just once',
        //             counter_other: 'Changed language already {{count}} times',
        //             footer: {
        //                 date: 'Today is {{date, DATE_HUGE}}',
        //                 date_morning: 'Good morning! Today is {{date, DATE_HUGE}} | Have a nice day!',
        //                 date_afternoon: 'Good afternoon! It\'s {{date, DATE_HUGE}}',
        //                 date_evening: 'Good evening! Today was the {{date, DATE_HUGE}}'
        //             }
        //         }
        //     },
        //     de: {
        //         translation: {
        //             description: {
        //                 part1: 'Ändere <1>src/App.js</1> und speichere um neu zu laden.',
        //                 part2: 'Lerne React'
        //             },
        //             key: "{{what}} isDE {{how}}",
        //             counter_one: 'Die Sprache wurde erst ein mal gewechselt',
        //             counter_other: 'Die Sprache wurde {{count}} mal gewechselt',
        //             footer: {
        //                 date: 'Heute ist {{date, DATE_HUGE}}',
        //                 date_morning: 'Guten Morgen! Heute ist {{date, DATE_HUGE}} | Wünsche einen schönen Tag!',
        //                 date_afternoon: 'Guten Tag! Es ist {{date, DATE_HUGE}}',
        //                 date_evening: 'Guten Abend! Heute war {{date, DATE_HUGE}}'
        //             }
        //         }
        //     }
        // }
    });


export default i18n;