import {
    FETCH_VOTES_BY_CNTYS, START_LOADING_VOTES_BY_CNTYS,
    END_LOADING_VOTES_BY_CNTYS, UPDATE_VOTES_BY_CNTYS
} from '../constants/actionTypes';

import {getNewVotesObj} from './votesByUtils';

const initialState = { 
    isLoadingVotesByCntys: false, 
    pollId: 0, 
    votesByCntys: [], 
    pendingData: null,
};


const votesByCntys =  (state = initialState, action) => {
    
    switch (action.type) {
        case START_LOADING_VOTES_BY_CNTYS:
            console.log("start loading votes by countries at reducer");

            return { ...state, votesByCntys: [], isLoadingVotesByCntys: true, pollId: 0 };
        case END_LOADING_VOTES_BY_CNTYS:
            console.log("end loading votes by countries at reducer");
            return { ...state, isLoadingVotesByCntys: false };
        case UPDATE_VOTES_BY_CNTYS:
            console.log("UPDATE_VOTES_BY_CNTYS: entering the reducer to update votes by countries");

            
            //need to parse the form data and update the vote counts
            //now we know the country
            
            const {poll} =action.payload;

            if(state.pollId !== poll._id) {
                console.log("UPDATE_VOTES_BY_CNTYS: the poll id is different, add to pending data ");
                return {...state, pendingData: action.payload};
            }

            const newVotesObj = getNewVotesObj(action.payload, state.votesByCntys);

            return {...state, pollId: poll._id, votesByCntys: newVotesObj.sort((a, b) => parseInt(b.votes) - parseInt(a.votes))};

        case FETCH_VOTES_BY_CNTYS:
            console.log("FETCH_VOTES_BY_CNTYS: entering the reducer to set votes");
            const { data, pollId } = action.payload;
            // if(state.votesByCntys.length === 1) { 
            //     console.log("first vote, keep old record created by browser");
            //     return state;
            // }
            var votesObj = data;
            //todo3-pz, may need to delay updatePollsStats to call later. 
            if(state.pendingData) {
                //there is pending data. 
                console.log("FETCH_VOTES_BY_CNTYS: processing pending update")
                votesObj = getNewVotesObj(state.pendingData, data);
            }

            return {
                ...state, pollId, pendingData: null,
                votesByCntys: votesObj.sort((a, b) => parseInt(b.votes) - parseInt(a.votes))
            };
        default:
            return state;
    }
};

export default votesByCntys;