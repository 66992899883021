import {
    FETCH_POLL_REL, START_LOADING_POLL_REL,
    END_LOADING_POLL_REL, UPDATE_POLL_REL
} from '../constants/actionTypes';



const initialState = {
    isLoadingPollRel: false,
    pollId: 0,
    pollRel: null,
    pollRelArray: null,
    pendingData: null,

};


const addData = (pendingData, mapData) => {

    const { answers } = pendingData.formData;
    if (!answers || answers.length === 0) {
        return;
    }

    var data = [];
    var i = 0;
    while (i < answers.length - 1) {
        answers[i].answers.forEach(curAnswer => {
            answers[i + 1].answers.forEach(nextAnswer => {
                data.push({
                    qst1Id: answers[i].stmtId,
                    qst2Id: answers[i + 1].stmtId,
                    option1Id: curAnswer.answerId,
                    option2Id: nextAnswer.answerId
                });
            })})
        i++;
    }
    data.forEach(item => {
        var key = item.qst1Id + "-" + item.qst2Id + "-" + item.option1Id + "-" + item.option2Id;
        var curVotes = mapData.get(key);
        if (curVotes !== undefined) {
            curVotes++;
        }
        else {
            curVotes = 1;
        }
        mapData.set(key, curVotes);
    });
}

const pollRel = (state = initialState, action) => {

    switch (action.type) {
        case START_LOADING_POLL_REL:
            console.log("start loading votes by regions at reducer");

            return { ...state, pollRel: null, pollRelArray: null, isLoadingPollRel: true, pollId: 0 };
        case END_LOADING_POLL_REL:
            console.log("end loading votes by regions at reducer");
            return { ...state, isLoadingPollRel: false };
        case UPDATE_POLL_REL:
            console.log("UPDATE_POLL_REL: entering the reducer to update poll rels");


            //need to parse the form data and update the vote counts
            //now we know the country

            const { poll } = action.payload;

            if (state.pollId !== poll._id) {
                console.log("UPDATE_POLL_REL: the poll id is different, add to pending data ");
                return { ...state, pendingData: action.payload };
            }

            var mapData2 = state.pollRel;
            if (!mapData2) mapData2 = new Map();
            addData(action.payload, mapData2);
            return { ...state, pendingData: null, pollRel: mapData2, pollRelArray: [...mapData2] };

        case FETCH_POLL_REL:
            console.log("FETCH_POLL_REL: entering the reducer to set votes");
            const { data, pollId } = action.payload;

            //don't understand logic now, comment it out. 
            // if (state.pollRel) {
            //     console.log("first vote, keep old record created by browser");
            //     return state;
            // }


            //convert to the hashtable.
            const mapData = new Map();
            data.forEach(item => {
                const key = item.qst1Id + "-" + item.qst2Id + "-" + item.option1Id + "-" + item.option2Id;
                mapData.set(key, Number(item.votes));
            });

            //todo3-pz, may need to delay updatePollsStats to call later. 
            if (state.pendingData) {
                //there is pending data. 
                console.log("FETCH_POLL_REL: processing pending update")
                addData(state.pendingData, mapData);
            }

            return { ...state, pollId, pendingData: null, pollRel: mapData, pollRelArray: [...mapData] };
        default:
            return state;
    }
};

export default pollRel;