import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    AppBar, Typography,
    Avatar, Toolbar,
    Button, useTheme,
    CssBaseline,
    Divider,
    useMediaQuery, Popover
} from '@material-ui/core';
import useStyles from './styles';
import decode from 'jwt-decode';
import { Link, useLocation, useHistory } from 'react-router-dom';
import DrawerComponent from './Drawer';
import { CgLogOut } from 'react-icons/cg';
import { refresh, createIncognitoUser } from '../../actions/auth';
import SearchField from "react-search-field";
import { useTranslation, Trans } from 'react-i18next';


var timeoutFunc = null;

const Navbar = () => {
    const { t, i18n } = useTranslation();
    const refreshStatus = useSelector((state) => state.auth.refreshStatus);
    const classes = useStyles();
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    const [search, setSearch] = useState('');
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const { pathname } = useLocation();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    console.log("achchorEL value", anchorEl);
    const id = open ? 'up-popover' : undefined;
    //const toastId = React.useRef(null);

    var currentPath = pathname;
    currentPath = currentPath.toLowerCase();
    console.log("NavBar: current Path: " + currentPath);

    //toastId.current = toast("currentPath: " + currentPath);
    if (user)
        console.log("NavBar: user is not empty");
    else
        console.log("NavBar: user is empty");

    var signInRedirect = '/' + i18n.resolvedLanguage + '/auth';
    var signupRedirect = '/' + i18n.resolvedLanguage + '/signup';
    if (currentPath === '/signup' || currentPath === "/auth"
        || currentPath === '/' + i18n.resolvedLanguage + '/signup'
        || currentPath === '/' + i18n.resolvedLanguage + '/auth') {
        currentPath = '/' + i18n.resolvedLanguage;
    }



    const logout = () => {
        dispatch({
            type: 'LOGOUT'
        });
        setUser(null);
        setAnchorEl(null);
        //history.push('/');
    }

    if (currentPath !== '/' || currentPath !== '/' + i18n.resolvedLanguage) {
        signInRedirect = '/' + i18n.resolvedLanguage + '/auth?redirect=' + currentPath;
        signupRedirect = '/' + i18n.resolvedLanguage + '/signup?redirect=' + currentPath;
    }

    const scheduleRenew = () => {
        timeoutFunc = setTimeout(() => {
            console.log("=====>renew timer start");
            timeoutFunc = null;
            const profile = JSON.parse(localStorage.getItem('profile'));
            if (profile?.rToken) {
                console.log("======>renew on timer")
                dispatch(refresh({ rToken: profile?.rToken }));
            }
            console.log("=====>schedule next renew");
            scheduleRenew();
        }, 60000 * 60);

    }

    useEffect(() => {
        console.log("useEffect at NavBar");
        let pos = currentPath.toLowerCase().search("polls/search");

        if (pos < 0) {
            console.log("cleaning search");
            setSearch("");
        }
        console.log("===>check token expiration at navbar");
        const newToken = JSON.parse(localStorage.getItem('profile'))?.token;


        if (newToken !== user?.token) {
            console.log("token is changed");
            setUser(JSON.parse(localStorage.getItem('profile')));
        }
        else {
            console.log("token is not changed");
        }

        const token = user?.token;
        const rToken = user?.rToken;

        if (token && !timeoutFunc) {
            console.log("=======>setup timer for renew");
            scheduleRenew();
        }
        else {
            console.log("========>renew timer is already set");
        }

        if (token && rToken) {
            const decodedToken = decode(token);
            //renew token when it is about expiring within 5 minutes
            if (decodedToken.exp * 1000 < new Date().getTime()) {
                //                console.log("====>token is expired, log out");
                console.log("=====>renew token while token is expired");

                dispatch(refresh({ rToken }));
                //logout();
            }
            else if ((decodedToken.exp - 300) * 1000 < new Date().getTime()) {
                console.log("=====>renew token while token is not expired yet");
                dispatch(refresh({ rToken }));
            }

        }
        else {
            console.log("=====>no rtoken or token, won't renew");
            //create annoymous user tracking
            const anon = JSON.parse(localStorage.getItem('anon-t'));
            if (!anon) {
                console.log("gen anon user");
                dispatch(createIncognitoUser());
            }
            else {
                console.log("anon user exists");
            }
        }




    }, [pathname]);

    // if(!authData && user) {
    //     console.log("======>auth data is null, init log out");
    //     logout();
    // }

    const searchPoll = (value) => {
        console.log('search pool with value', value);
        if (search.trim().length > 0) {
            //dispatch to search Polls
            //dispatch(getPollsBySearch({search, tags: tags.join(','), page}));
            var tagsQuery = search
            ///polls/search?searchQuery=${searchQuery}&tags=${tagsQuery}&page=${item.page}
            history.push('/' + i18n.resolvedLanguage + `/polls/search?searchQuery=${search}&tags=${tagsQuery}&page=1`);
        } else {
            history.push('/' + i18n.resolvedLanguage + '/');
        }
    }
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const profileMenuSec2 = () => (
        <div>
            {/* <Button aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
                Open Popover
            </Button> */}
            <div onClick={handleClick}><Avatar className={classes.purple} alt={user.result.name} src={user.result.imageUrl} >
                {user.result.name.charAt(0)}
            </Avatar></div>
            <Popover
                style={{ marginTop: "5px" }}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div style={{ margin: "5px 5px 5px 5px" }}>
                    <div className={classes.persona}>
                        <Avatar className={classes.purple} alt={user.result.name} src={user.result.imageUrl} >
                            {user.result.name.charAt(0)}
                        </Avatar>
                        <div className={classes.personaGroupText}>
                            <p className={classes.personaPrimaryText}>{user.result.name}</p>
                            <p className={classes.personaText}>{user.result.email}</p>
                        </div>

                    </div>

                    <Divider className={classes.personaDivider} />


                    <div className={classes.personaMenu} onClick={logout}>
                        <CgLogOut size="1.2rem"></CgLogOut>
                        <Typography variant="caption" className={classes.personaMenuText}>{t("menu.signout")}</Typography>
                    </div>

                </div>
            </Popover>
        </div>
    );

    const menuSec = (isMobile) => {
        return isMobile ? (
            <DrawerComponent signInRedirect={signInRedirect} signupRedirect={signupRedirect} />
        ) : (
            <>
                <Button component={Link} to={'/' + i18n.resolvedLanguage} className={classes.textButton}
                    variant="text" color="primary" >{t("menu.pollList")}</Button>

                {i18n.resolvedLanguage === "zh" && <Button component={Link} to={'/' + i18n.resolvedLanguage + '/blogs'} className={classes.textButton}
                    variant="text" color="primary" >{t("menu.blogs")}</Button>}
                    
                <Button component={Link} to={signInRedirect} className={classes.login}
                    variant="outlined" color="primary" >{t("menu.login")}</Button>

                <Button component={Link} to={signupRedirect} className={classes.register}
                    variant="contained" color="primary" >{t("menu.signup")}</Button>
            </>
        );
    }

    return (


        <AppBar className={classes.appBar} color='transparent' elevation={0} position="static"  >
            <CssBaseline />
            <div className={classes.brandContainer}>
                {/* <img className={classes.image} src={votes} alt="votes" height="40" component={Link} to={'/' + i18n.resolvedLanguage } /> */}
                <Typography component={Link} to={'/' + i18n.resolvedLanguage} className={classes.heading} style={{ fontFamily: 'Lucida Console', color: '#4248B9' }} variant="h6" align="center">{t("common.prodName2")}</Typography>


            </div>
            {/* <SearchBar
                placeholder="Search Polls"
                value={search}
                className={classes.searchBar}
                onChange={(newValue) => setSearch(newValue)}
                onRequestSearch={() => searchPoll()}
            /> */}

            <SearchField
                placeholder={t("home.search")}
                searchText={search}
                classNames={classes.searchBar}
                onChange={(newValue) => setSearch(newValue)}
                onSearchClick={searchPoll}
                onEnter={searchPoll}

            />
            {refreshStatus === 10 &&
                <Typography className={classes.heading} variant="h4" align="center">refresh failed</Typography>}
            <Toolbar className={classes.toolbar} >
                {(refreshStatus !== 0 && user) ?
                    (
                        <div className={classes.profile}>

                            {profileMenuSec2()}

                            {/* <Typography className={classes.userName} variant="h6">{user.result.name}
                            </Typography>
                            <Button variant="contained" className={classes.logout}
                                color="secondary" onClick={logout}>Logout</Button>
 */}
                        </div>
                    )
                    : (
                        menuSec(isMobile)
                    )}

            </Toolbar>

        </AppBar>

    )
}

export default Navbar
