import { combineReducers } from "redux";

import auth from './auth';
import error from './error';
import polls from './polls';
import userVotes from './userVotes';
import votesByCntys from "./votesByCntys";
import votesByRegs from "./votesByRegs";
import tags from './tags';
import pollRel from './pollRel';
import votesWeekly from "./votesWeekly";

export default combineReducers({
    auth,
    error,
    polls,
    userVotes,
    votesByCntys,
    tags,
    votesByRegs,
    pollRel,
    votesWeekly,
});